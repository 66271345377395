// Generated by Framer (d03ec02)

import { addFonts, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["wKVRm3yjz"];

const serializationHash = "framer-fU4Fz"

const variantClassNames = {wKVRm3yjz: "framer-v-dam6id"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wKVRm3yjz", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} style={{display: "contents"}}>
<Transition value={transition}><motion.figure {...restProps} aria-label={"Netflix logo"} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dam6id", className, classNames)} data-framer-name={"Netflix"} layoutDependency={layoutDependency} layoutId={"wKVRm3yjz"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fit", intrinsicHeight: 415, intrinsicWidth: 337, pixelHeight: 415, pixelWidth: 337, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/4foG0QEf88CEKFs1o6RtH1Ggx4.png"}} className={"framer-i5fwc0"} data-framer-name={"Frame_5"} layoutDependency={layoutDependency} layoutId={"cT8UsiZ1p"}/></motion.figure></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fU4Fz[data-border=\"true\"]::after, .framer-fU4Fz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fU4Fz.framer-sgo4g9, .framer-fU4Fz .framer-sgo4g9 { display: block; }", ".framer-fU4Fz.framer-dam6id { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 90px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 178px; }", ".framer-fU4Fz .framer-i5fwc0 { aspect-ratio: 1.9777777777777779 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 90px); overflow: visible; position: relative; width: 178px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fU4Fz.framer-dam6id { gap: 0px; } .framer-fU4Fz.framer-dam6id > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-fU4Fz.framer-dam6id > :first-child { margin-left: 0px; } .framer-fU4Fz.framer-dam6id > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 178
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramerqDtzXSY_x: React.ComponentType<Props> = withCSS(Component, css, "framer-fU4Fz") as typeof Component;
export default FramerqDtzXSY_x;

FramerqDtzXSY_x.displayName = "Netflix";

FramerqDtzXSY_x.defaultProps = {height: 90, width: 178};

addFonts(FramerqDtzXSY_x, [])